<template>
  <VForm @submit="createUpdateContent" ref="form" v-slot="{ errors }">
    <div class="modal-body p-4">
      <div class="row mb-3 align-items-center">
        <div class="col-md-7">
          <div
            class="w-100 bg-light mb-2 d-flex justify-content-center align-items-center"
            :class="brand.imagePath ? 'show' : ''"
            style="height: 120px"
          >
            <img
              v-if="brand.imagePath"
              class="rounded-start w-100 obj-fit-contain"
              :src="$imageFilters(brand.imagePath)"
              alt="圖片"
            />
          </div>
        </div>
        <div class="col-md-5">
          <AppUpload @uploadSuccess="uploadSuccess" title="公司LOGO" />
        </div>
      </div>
      <div class="mb-3">
        <div class="row mb-3">
          <div class="col-6">
            <label for="nameCh" class="form-label">中文名稱</label>
            <div class="input-group">
              <VField
                id="title"
                name="中文名稱"
                type="text"
                class="form-control border"
                :class="{ 'is-invalid': errors['中文名稱'] }"
                placeholder="輸入中文名稱"
                v-model="brand.nameCh"
                maxlength="50"
                rules="max:50|required"
              />
              <span class="input-group-text bg-white fs-9 text-info p-2"
                >{{ brand.nameCh?.length }}/50</span
              >
            </div>
          </div>
          <div class="col-6">
            <label for="title" class="form-label">英文名稱</label>
            <div class="input-group">
              <VField
                id="title"
                name="英文名稱"
                type="text"
                class="form-control border"
                :class="{ 'is-invalid': errors['英文名稱'] }"
                placeholder="輸入英文名稱"
                v-model="brand.nameEn"
                maxlength="100"
                rules="max:100|required"
              />
              <span class="input-group-text bg-white fs-9 text-info p-2"
                >{{ brand.nameEn?.length }}/100</span
              >
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="url" class="form-label">公司網址</label>
          <div class="input-group">
            <VField
              id="url"
              name="網址"
              type="text"
              class="form-control border"
              :class="{ 'is-invalid': errors['網址'] }"
              placeholder="輸入網址"
              v-model="brand.url"
              maxlength="300"
              rules="url|max:300"
            />
            <span class="input-group-text bg-white fs-9 text-info p-2"
              >{{ brand.url?.length }}/300</span
            >
          </div>
        </div>
        <div class="mb-3">
          <label for="url" class="form-label">公司簡介(限1000字)</label>
          <textarea v-model="brand.description" class="form-control" rows="10" maxlength="1000">
          </textarea>
        </div>
        <div class="d-flex align-items-center mt-3">
          <label class="form-label m-0" for="article-active">是否為合作夥伴</label>
          <div class="switch-group ms-2">
            <input type="checkbox" id="article-active" role="button" v-model="brand.isPartner" />
            <div class="ico_switch"></div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-3">
          <label class="form-label m-0" for="article-active">是否公開</label>
          <div class="switch-group ms-2">
            <input type="checkbox" id="article-active" role="button" v-model="brand.isPublished" />
            <div class="ico_switch"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">取消</button>
      <button type="submit" class="btn btn-primary">儲存</button>
    </div>
  </VForm>
</template>

<script>
import { apiCreateBrand, apiUpdateBrand } from "@/api/brand";

export default {
  props: {
    propItems: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ["emitter"],
  data() {
    return {
      brand: {
        imageId: "",
        nameEn: "",
        nameCh: "",
        isPartner: false,
        url: "",
        isPublished: false,
        description: "",
      },
    };
  },
  computed: {
    brandData() {
      const brandItems = [
        "imageId",
        "url",
        "nameEn",
        "nameCh",
        "isPartner",
        "isPublished",
        "description",
      ];
      return brandItems.reduce((acc, item) => {
        return Object.is(this.brand[item], this.propItems[item])
          ? acc
          : { ...acc, [item]: this.brand[item] };
      }, {});
    },
  },
  methods: {
    async createUpdateContent() {
      if (!this.brand.imagePath) {
        this.emitter.emit("notice-message", { style: "warning", title: "請上傳圖片" });
        return;
      }
      try {
        this.$vLoading(true);
        const { nameEn: propsNameEn } = this.propItems;
        const res = propsNameEn
          ? await apiUpdateBrand(propsNameEn, this.brandData)
          : await apiCreateBrand(this.brandData);
        const { code, message } = res.data;
        if (code === 200) {
          this.$vHttpsNotice({
            statusCode: res.status,
            title: `${propsNameEn ? "編輯" : "新增"}廠商`,
          });
          this.$emit("finishContent");
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    uploadSuccess(item) {
      const { imageId, imagePath } = item;
      this.brand.imagePath = imagePath;
      this.brand.imageId = imageId;
    },
    init() {
      this.brand = {
        imageId: "",
        nameEn: "",
        nameCh: "",
        isPartner: false,
        url: "",
        isPublished: false,
        description: "",
      };
    },
  },
  watch: {
    propItems(val) {
      if (val.nameEn) {
        this.brand = { ...val };
      } else {
        this.$refs.form.resetForm();
        this.init();
      }
    },
  },
};
</script>
