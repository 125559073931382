<template>
  <VForm class="container" @submit="createUpdateContent" ref="form" v-slot="{ errors }">
    <div class="row">
      <div class="col-lg-4">
        <label for="title" class="form-label">既有廠商*</label>
        <p v-if="brandEn">
          {{ brandEn }}
        </p>
        <div v-else>
          <select v-model="productAgent.brandId" class="form-select" aria-label=".form-select">
            <option value="">請選擇</option>
            <option v-for="a in agents" :key="a.nameEn" :value="a.id">
              {{ a.nameCh }} | {{ a.nameEn }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="mb-3">
          <label for="title" class="form-label">產品分類*</label>
          <select v-model="productAgent.productId" class="form-select" aria-label=".form-select">
            <option value="">請選擇</option>
            <option v-for="a in products" :key="a.nameEn" :value="a.id">
              {{ a.nameCh }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-4">
        <div
          class="w-100 bg-light banner-image mb-2"
          :class="productAgent.imagePath ? 'show' : ''"
          style="height: 150px"
        >
          <img
            v-if="productAgent.imagePath"
            class="rounded-start w-100"
            :src="$imageFilters(productAgent.imagePath)"
            alt="圖片"
          />
        </div>
        <AppUpload @uploadSuccess="uploadSuccess" />
      </div>
      <div class="col-8">
        <div class="mb-3">
          <label for="name" class="form-label">產品名稱</label>
          <div class="input-group">
            <VField
              id="name"
              name="產品名稱"
              type="text"
              class="form-control border"
              :class="{ 'is-invalid': errors['產品名稱'] }"
              placeholder="輸入產品名稱"
              v-model="productAgent.name"
              maxlength="50"
              rules="max:50|required"
            />
            <span class="input-group-text bg-white fs-9 text-info p-2"
              >{{ productAgent.name?.length }}/50</span
            >
          </div>
        </div>
        <div class="mb-3">
          <label for="redirectPath" class="form-label">產品網址</label>
          <div class="input-group">
            <VField
              id="redirectPath"
              name="產品網址"
              type="text"
              class="form-control border"
              :class="{ 'is-invalid': errors['產品網址'] }"
              placeholder="輸入網址"
              v-model="productAgent.url"
              maxlength="300"
              rules="url|max:300"
            />
            <span class="input-group-text bg-white fs-9 text-info p-2"
              >{{ productAgent.url?.length }}/300</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-2 col-12">
        <label for="content" class="form-label">內容</label>
        <textarea v-model="productAgent.content" class="form-control" rows="10" maxlength="1000">
        </textarea>
      </div>
      <div class="d-flex align-items-center my-3">
        <label class="form-label m-0" for="article-active">是否公開</label>
        <div class="switch-group ms-2">
          <input
            type="checkbox"
            id="article-active"
            role="button"
            v-model="productAgent.isPublished"
          />
          <div class="ico_switch"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('cancel')">
          取消
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :disable="Object.keys(productItemData).length === 0"
        >
          儲存
        </button>
      </div>
    </div>
  </VForm>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { apiQueryAdminProduct, apiCreateProductItem, apiUpdateProductItem } from "@/api/product";

export default {
  props: {
    brandEn: {
      type: String,
    },
    propItems: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      agents: [],
      products: [],
      originalProductId: "",
      productAgent: {
        productId: "",
        brandId: "",
        name: "",
        content: "",
        url: "",
        imageId: "",
        isPublished: true,
      },
      originalItems: {},
    };
  },
  computed: {
    ...mapGetters("brand", ["brandEnInfo"]),
    currentBrandId() {
      return this.brandEnInfo(this.brandEn)?.id;
    },
    productItemData() {
      const productItems = [
        "productId",
        "brandId",
        "name",
        "url",
        "isPublished",
        "content",
        "imageId",
      ];
      return productItems.reduce((acc, item) => {
        return Object.is(this.productAgent[item], this.originalItems[item])
          ? acc
          : { ...acc, [item]: this.productAgent[item] };
      }, {});
    },
  },
  methods: {
    ...mapActions("brand", ["queryBrands"]),
    async createUpdateContent() {
      try {
        this.$vLoading(true);
        const { productId, brandId, imagePath } = this.productAgent;
        if (!imagePath) {
          this.$vHttpsNotice({ statusCode: 9001, title: "請上傳圖片" });
        } else if (productId && brandId) {
          const data = { ...this.productItemData };
          if (this.originalProductId === productId) {
            delete data.productId;
          }
          const res = this.originalItems.uuid
            ? await apiUpdateProductItem(this.originalItems.uuid, data)
            : await apiCreateProductItem(data);
          const { code, message } = res.data;

          if (code === 200) {
            this.$vHttpsNotice({
              statusCode: res.status,
              title: `${this.originalItems.uuid ? "編輯" : "新增"}產品子項`,
            });
            this.$refs.form.resetForm();
            this.init();
            this.$emit("finishContent");
          } else {
            this.$vHttpsNotice({ statusCode: code, title: message });
          }
        } else {
          this.$vHttpsNotice({ statusCode: 9001, title: "請選擇產品類型和廠商名稱" });
        }
      } catch (e) {
        // regardless
      } finally {
        this.$vLoading(false);
      }
    },
    async queryProduct(type, size = 6) {
      this.$vLoading(true);
      try {
        const res = await apiQueryAdminProduct({ type, size });
        const { code, data, message } = res.data;
        if (code === 200) {
          this.products = data;
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    init() {
      this.originalItems = {};
      this.productAgent = {
        productId: "",
        brandId: "",
        name: "",
        content: "",
        url: "",
        isPublished: true,
      };
    },
    uploadSuccess(item) {
      const { imageId, imagePath } = item;
      this.productAgent.imagePath = imagePath;
      this.productAgent.imageId = imageId;
    },
  },
  watch: {
    async propItems(val) {
      if (!this.currentBrandId) {
        await this.queryBrands();
      }
      if (val.uuid) {
        const { product, ...rest } = val;
        this.productAgent = rest;
        if (product) {
          const productItem = this.products.find((p) => p.nameEn === product.productNameEn);
          this.productAgent.productId = productItem && productItem.id;
          this.originalItems = { ...val, productId: productItem.id, brandId: this.currentBrandId };
        }
      } else {
        this.$refs.form.resetForm();
        this.init();
      }
      this.productAgent.brandId = this.currentBrandId;
    },
  },
  async created() {
    await this.queryBrands();
    await this.queryProduct();
    if (this.brandEn) {
      this.productAgent.brandId = this.currentBrandId;
    }
  },
};
</script>
